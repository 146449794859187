// src/main.tsx

import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'; // React Query import
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import { App } from './App';
import './index.css';
import { router } from './router/routes';
import { initializeGA } from './utils/analytics';

initializeGA(); // Initialize Google Analytics

// Create a QueryClient instance for React Query
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router}>
          <App />
        </RouterProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);
