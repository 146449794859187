// src/components/common/BackToTopButtonWrapper.tsx

import { useLocation } from 'react-router-dom';

import { BackToTopButton } from './BackToTopButton';

const hiddenRoutes = [
  '/about',
  '/contact',
  '/sitemap',
  '/card-generator',
  '/grid-generator',
  '/flexbox-generator',
  '/tooltip-generator',
  '/box-shadow-generator',
  '/text-shadow-generator',
  '/faq',
  '/privacy-policy',
  '/terms-of-service',
];

export const BackToTopButtonWrapper = () => {
  const location = useLocation();
  const shouldHideButton = hiddenRoutes.includes(location.pathname);

  return !shouldHideButton ? <BackToTopButton /> : null;
};
