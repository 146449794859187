// src/utils/analytics.ts

import ReactGA from 'react-ga4';

let analyticsInitialized = false;

// Initialize Google Analytics
export const initializeGA = () => {
  try {
    ReactGA.initialize('G-3QWX5DEJ3D'); // Replace with your Measurement ID
    analyticsInitialized = true;
    // eslint-disable-next-line no-console
    console.log('[Analytics] Initialized successfully');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('[Analytics] Initialization failed:', error);
    analyticsInitialized = false;
  }
};

// Track a pageview
export const trackPageView = (path: string) => {
  if (!analyticsInitialized) {
    // eslint-disable-next-line no-console
    console.warn(
      '[Analytics] Google Analytics is not initialized. Skipping pageview tracking.'
    );

    return;
  }
  ReactGA.send({ hitType: 'pageview', page: path });
};

// Track an event
export const trackEvent = (
  action: string,
  category: string,
  label?: string
) => {
  if (!analyticsInitialized) {
    // eslint-disable-next-line no-console
    console.warn(
      '[Analytics] Google Analytics is not initialized. Skipping event tracking.'
    );

    return;
  }
  ReactGA.event({
    action,
    category,
    label,
  });
};
