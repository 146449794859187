// App.tsx

import { Helmet } from 'react-helmet-async';
import Modal from 'react-modal';

import './App.css';
import { AppRouter } from './router/AppRouter';

Modal.setAppElement('#root');

export const App = () => (
  <div className="App" data-testid="app-root">
    <Helmet>
      <title>RoundCodeBox - AI SEO Tool for Top Performance</title>
      <meta
        name="description"
        content="Boost your website SEO with RoundCodeBox, the AI-powered tool for effortless analysis and higher search rankings."
      />
      <link rel="canonical" href="https://www.roundcodebox.com/" />
    </Helmet>
    <AppRouter />
  </div>
);
