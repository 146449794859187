// src/components/GuestRoute.tsxsrc/components/GuestRoute.tsx

import React from 'react';

import { Navigate } from 'react-router-dom';

interface GuestRouteProps {
  element: JSX.Element;
}

export const GuestRoute: React.FC<GuestRouteProps> = ({ element }) => {
  const token = localStorage.getItem('token');

  // If the token exists, the user is logged in, so redirect to the dashboard
  if (token) {
    return <Navigate to="/user-dashboard" replace />;
  }

  // If no token, render the guest-only element (e.g., login or register page)
  return element;
};
