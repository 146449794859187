// src/components/ProtectedRoute.tsx

import React from 'react';

import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  element: JSX.Element;
  requiredRole?: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  requiredRole,
}) => {
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  // Check if the token exists
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // Decode the token and check expiry
  const payload = JSON.parse(atob(token.split('.')[1]));
  const isTokenExpired = Date.now() >= payload.exp * 1000;

  if (isTokenExpired) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    return <Navigate to="/login" replace />;
  }

  // Check if the user's role matches the required role
  if (requiredRole && user.role !== requiredRole) {
    return <Navigate to="/login" replace />;
  }

  return element;
};
