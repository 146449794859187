// src/components/common/Header.tsx

import React, { useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import './Header.css';

const megaMenuVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  exit: { opacity: 0, y: -20, transition: { duration: 0.2 } },
};

const menuItemVariants = {
  hover: {
    scale: 1.05,
    color: '#1d4ed8',
    transition: { duration: 0.2 },
  },
};

const underlineVariants = {
  hidden: {
    scaleX: 0,
    transformOrigin: 'left',
    transition: { duration: 0.2 },
  },
  visible: {
    scaleX: 1,
    transformOrigin: 'left',
    transition: { duration: 0.2 },
  },
};

export const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleMegaMenu = () => {
    setIsMegaMenuOpen(!isMegaMenuOpen);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleMegaMenu();
    }
  };

  return (
    <header
      className="header-fixed top-0 z-10 w-full bg-white shadow-md"
      data-testid="header">
      <div className="header-container mx-auto flex items-center justify-between px-4 py-2">
        <div className="header-brand flex items-center space-x-2 pl-16">
          <a
            href="/"
            className="text-2xl font-semibold text-blue-600"
            data-testid="logo">
            RoundCodeBox
          </a>
        </div>
        <div className="header-nav hidden w-full justify-center md:flex">
          <nav className="header-nav-items flex items-center space-x-4">
            <a
              href="/"
              className="text-gray-600 hover:text-gray-800"
              data-testid="nav-home">
              Home
            </a>
            <div
              className="header-mega-menu group relative"
              role="button"
              tabIndex={0}
              onClick={toggleMegaMenu}
              onKeyDown={handleKeyDown}
              data-testid="nav-tools">
              Tools & Generator
              <AnimatePresence>
                {isMegaMenuOpen && (
                  <motion.div
                    className="header-mega-menu-content absolute pt-2"
                    style={{ left: '-350px' }}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={megaMenuVariants}
                    data-testid="mega-menu">
                    <div className="header-mega-menu-inner grid w-max max-w-6xl grid-cols-3 gap-x-8 rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
                      <div className="flex flex-col space-y-2">
                        <motion.a
                          href="/card-generator"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-card-generator">
                          Card Generator
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/grid-generator"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-grid-generator">
                          Grid Generator
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/flexbox-generator"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-flexbox-generator">
                          Flexbox Generator
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/tooltip-generator"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-tooltip-generator">
                          Tooltip Generator
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/box-shadow-generator"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-box-shadow-generator">
                          Box Shadow Generator
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/text-shadow-generator"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-text-shadow-generator">
                          Text Shadow Generator
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <motion.a
                          href="/flexbox-generator-details"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-flexbox-generator-details">
                          Flexbox Generator Details
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/grid-generator-details"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-grid-generator-details">
                          Grid Generator Details
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/tooltip-generator-details"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-tooltip-generator-details">
                          Tooltip Generator Details
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/card-generator-details"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-card-generator-details">
                          Card Generator Details
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/text-shadow-generator-details"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-text-shadow-generator-details">
                          Text Shadow Generator Details
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/box-shadow-generator-details"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-box-shadow-generator-details">
                          Box Shadow Generator Details
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <motion.a
                          href="/faq"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-faq">
                          FAQ
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/about"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-about">
                          About
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/contact"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-contact">
                          Contact
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/privacy-policy"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-privacy-policy">
                          Privacy Policy
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/terms-of-service"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-terms-of-service">
                          Terms of Service
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                        <motion.a
                          href="/sitemap"
                          className="m-1 p-2 text-gray-600 hover:text-gray-800"
                          variants={menuItemVariants}
                          whileHover="hover"
                          data-testid="menu-sitemap">
                          Sitemap
                          <motion.span
                            className="mt-1 block h-1 bg-blue-600"
                            initial="hidden"
                            whileHover="visible"
                            variants={underlineVariants}
                          />
                        </motion.a>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <a
              href="/blog"
              className="text-gray-600 hover:text-gray-800"
              data-testid="nav-blog">
              Blog
            </a>
          </nav>
        </div>
        <div className="header-get-started hidden pr-16 md:flex">
          <a
            href="/get-started"
            className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
            data-testid="nav-get-started">
            Get started
          </a>
        </div>
        <div className="header-mobile-menu md:hidden">
          <button
            type="button"
            onClick={toggleMenu}
            aria-label="Toggle menu"
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            data-testid="menu-toggle">
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
              />
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div
          className="header-mobile-nav flex items-center justify-center md:hidden"
          data-testid="mobile-nav">
          <nav className="flex flex-col space-y-4 px-4 py-2">
            <a
              href="/"
              className="text-gray-600 hover:text-gray-800"
              data-testid="mobile-nav-home">
              Home
            </a>
            <a
              href="/tools"
              className="text-gray-600 hover:text-gray-800"
              data-testid="mobile-nav-tools">
              Tools & Generator
            </a>
            <a
              href="/faq"
              className="text-gray-600 hover:text-gray-800"
              data-testid="mobile-nav-faq">
              FAQ
            </a>
            <a
              href="/blog"
              className="text-gray-600 hover:text-gray-800"
              data-testid="mobile-nav-blog">
              Blog
            </a>
            <a
              href="/get-started"
              className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
              data-testid="mobile-nav-get-started">
              Get started
            </a>
          </nav>
        </div>
      )}
    </header>
  );
};
