// src/components/common/BackToTopButton.tsx

import React from 'react';

import { motion } from 'framer-motion';
import { FiArrowUp } from 'react-icons/fi';

export const BackToTopButton: React.FC = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className="fixed bottom-6 right-6 z-50 rounded-full bg-blue-600 p-4 text-white shadow-lg"
      onClick={handleClick}
      data-testid="back-to-top-button"
      aria-label="Back to top">
      <FiArrowUp data-testid="arrow-icon" />
    </motion.button>
  );
};
