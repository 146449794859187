// src/components/AnalyticsTracker.tsx

import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { trackPageView } from '../utils/analytics';

export const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname); // Track pageview on route change
  }, [location]);

  return null;
};
