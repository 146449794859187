// src/utils/lazyWithNamedExports.ts
import React, { LazyExoticComponent } from 'react';

type ModuleWithComponent<T extends string> = {
  [K in T]: React.ComponentType;
};

export function lazyWithNamedExports<T extends string>(
  factory: () => Promise<ModuleWithComponent<T>>,
  name: T
): LazyExoticComponent<React.ComponentType> {
  return React.lazy(() =>
    factory().then((module) => ({ default: module[name] }))
  );
}
