// src/router/routes.tsx
import { Suspense } from 'react';

import { createBrowserRouter, Outlet } from 'react-router-dom';

import { AnalyticsTracker } from '../components/AnalyticsTracker';
import { BackToTopButtonWrapper } from '../components/common/BackToTopButtonWrapper'; // Ensure this import is correct
import { Footer } from '../components/common/Footer';
import { GuestRoute } from '../components/common/GuestRoute';
import { Header } from '../components/common/Header';
import { ProtectedRoute } from '../components/common/ProtectedRoute';
import { lazyWithNamedExports } from '../utils/lazyWithNamedExports';

// Dynamically imported components using lazyWithNamedExports
const HomePage = lazyWithNamedExports(
  () => import('../pages/HomePage'),
  'HomePage'
);
const CardGenerator = lazyWithNamedExports(
  () => import('../components/CardGenerator/CardGenerator'),
  'CardGenerator'
);
const HtmlMinifier = lazyWithNamedExports(
  () => import('../components/HtmlMinifier/HtmlMinifier'),
  'HtmlMinifier'
);
const CssMinifier = lazyWithNamedExports(
  () => import('../components/CssMinifier/CssMinifier'),
  'CssMinifier'
);
const JsMinifier = lazyWithNamedExports(
  () => import('../components/JsMinifier/JsMinifier'),
  'JsMinifier'
);
const CardGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/CardGeneratorDetails'),
  'CardGeneratorDetails'
);
const GridGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/GridGeneratorDetails'),
  'GridGeneratorDetails'
);
const FlexboxGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/FlexboxGeneratorDetails'),
  'FlexboxGeneratorDetails'
);
const TextShadowGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/TextShadowGeneratorDetails'),
  'TextShadowGeneratorDetails'
);
const GridGenerator = lazyWithNamedExports(
  () => import('../components/GridGenerator/GridGenerator'),
  'GridGenerator'
);
const FlexboxGenerator = lazyWithNamedExports(
  () => import('../components/FlexboxGenerator/FlexboxGenerator'),
  'FlexboxGenerator'
);
const TooltipGenerator = lazyWithNamedExports(
  () => import('../components/TooltipGenerator/TooltipGenerator'),
  'TooltipGenerator'
);
const TooltipGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/TooltipGeneratorDetails'),
  'TooltipGeneratorDetails'
);
const TextShadowGenerator = lazyWithNamedExports(
  () => import('../components/TextShadowGenerator/TextShadowGenerator'),
  'TextShadowGenerator'
);
const LoginPage = lazyWithNamedExports(
  () => import('../pages/LoginPage'),
  'LoginPage'
);
const RegistrationPage = lazyWithNamedExports(
  () => import('../pages/RegistrationPage'),
  'RegistrationPage'
);
const ForgotPasswordPage = lazyWithNamedExports(
  () => import('../pages/ForgotPasswordPage'),
  'ForgotPasswordPage'
);
const ResetPasswordPage = lazyWithNamedExports(
  () => import('../pages/ResetPasswordPage'),
  'ResetPasswordPage'
);
const PricingPage = lazyWithNamedExports(
  () => import('../pages/PricingPage'),
  'PricingPage'
);
const ReportGenerationPage = lazyWithNamedExports(
  () => import('../pages/ReportGenerationPage'),
  'ReportGenerationPage'
);
const ProjectsPage = lazyWithNamedExports(
  () => import('../pages/ProjectsPage'),
  'ProjectsPage'
);
// const ReportDashboardPage = lazyWithNamedExports(
//   () => import('../pages/ReportDashboardPage'),
//   'ReportDashboardPage'
// );
const ReportPage = lazyWithNamedExports(
  () => import('../pages/ReportPage'),
  'ReportPage'
);
const BlogPage = lazyWithNamedExports(
  () => import('../pages/BlogPage'),
  'BlogPage'
);
const SuperAdminDashboardPage = lazyWithNamedExports(
  () => import('../pages/SuperAdminDashboardPage'),
  'SuperAdminDashboardPage'
);
const AdminDashboardPage = lazyWithNamedExports(
  () => import('../pages/AdminDashboardPage'),
  'AdminDashboardPage'
);
const UserDashboardPage = lazyWithNamedExports(
  () => import('../pages/UserDashboardPage'),
  'UserDashboardPage'
);
const FaqPage = lazyWithNamedExports(
  () => import('../pages/FaqPage'),
  'FaqPage'
);
const AboutPage = lazyWithNamedExports(
  () => import('../pages/AboutPage'),
  'AboutPage'
);
const ContactPage = lazyWithNamedExports(
  () => import('../pages/ContactPage'),
  'ContactPage'
);
const PrivacyPolicyPage = lazyWithNamedExports(
  () => import('../pages/PrivacyPolicyPage'),
  'PrivacyPolicyPage'
);
const SitemapPage = lazyWithNamedExports(
  () => import('../pages/SitemapPage'),
  'SitemapPage'
);
const TermsOfServicePage = lazyWithNamedExports(
  () => import('../pages/TermsOfServicePage'),
  'TermsOfServicePage'
);
const BoxShadowGenerator = lazyWithNamedExports(
  () => import('../components/BoxShadowGenerator/BoxShadowGenerator'),
  'BoxShadowGenerator'
);
const BoxShadowGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/BoxShadowGeneratorDetails'),
  'BoxShadowGeneratorDetails'
);
const NotFound = lazyWithNamedExports(
  () => import('../components/NotFound'),
  'NotFound'
);

export const Layout = () => (
  <div>
    <Header />
    <AnalyticsTracker /> {/* Add the tracker */}
    <div className="content">
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
    </div>
    <Footer />
    <BackToTopButtonWrapper /> {/* Ensure this is included */}
  </div>
);

const routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { path: '', element: <HomePage /> },
      { path: 'card-generator', element: <CardGenerator /> },
      { path: 'html-minifier', element: <HtmlMinifier /> },
      { path: 'css-minifier', element: <CssMinifier /> },
      { path: 'js-minifier', element: <JsMinifier /> },
      { path: 'card-generator-details', element: <CardGeneratorDetails /> },
      { path: 'grid-generator-details', element: <GridGeneratorDetails /> },
      {
        path: 'flexbox-generator-details',
        element: <FlexboxGeneratorDetails />,
      },
      {
        path: 'text-shadow-generator-details',
        element: <TextShadowGeneratorDetails />,
      },
      { path: 'grid-generator', element: <GridGenerator /> },
      { path: 'flexbox-generator', element: <FlexboxGenerator /> },
      { path: 'tooltip-generator', element: <TooltipGenerator /> },
      {
        path: 'tooltip-generator-details',
        element: <TooltipGeneratorDetails />,
      },
      { path: 'text-shadow-generator', element: <TextShadowGenerator /> },
      // Guest-Only Routes
      { path: 'login', element: <GuestRoute element={<LoginPage />} /> },
      {
        path: 'register',
        element: <GuestRoute element={<RegistrationPage />} />,
      },
      {
        path: 'forgot-password',
        element: <GuestRoute element={<ForgotPasswordPage />} />,
      },
      {
        path: 'reset-password',
        element: <GuestRoute element={<ResetPasswordPage />} />,
      },
      { path: 'pricing', element: <GuestRoute element={<PricingPage />} /> },
      // Protected Routes
      {
        path: 'report-generation',
        element: <ProtectedRoute element={<ReportGenerationPage />} />,
      },
      {
        path: 'projects',
        element: <ProtectedRoute element={<ProjectsPage />} />,
      },
      // {
      //   path: 'report/:url',
      //   element: <ProtectedRoute element={<ReportDashboardPage />} />,
      // },
      {
        path: 'report/:reportId',
        element: <ProtectedRoute element={<ReportPage />} />,
      },
      { path: 'blog', element: <BlogPage /> },
      {
        path: 'user-dashboard',
        element: <ProtectedRoute element={<UserDashboardPage />} />,
      },
      {
        path: 'admin-dashboard',
        element: (
          <ProtectedRoute
            element={<AdminDashboardPage />}
            requiredRole="admin"
          />
        ),
      },
      {
        path: 'super-admin-dashboard',
        element: (
          <ProtectedRoute
            element={<SuperAdminDashboardPage />}
            requiredRole="super_admin"
          />
        ),
      },

      { path: 'faq', element: <FaqPage /> },
      { path: 'about', element: <AboutPage /> },
      { path: 'contact', element: <ContactPage /> },
      { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
      { path: 'terms-of-service', element: <TermsOfServicePage /> },
      { path: 'sitemap', element: <SitemapPage /> },
      { path: 'box-shadow-generator', element: <BoxShadowGenerator /> },
      {
        path: 'box-shadow-generator-details',
        element: <BoxShadowGeneratorDetails />,
      },
      { path: '*', element: <NotFound /> },
    ],
  },
];

export const router = createBrowserRouter(routes);
